@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,500,700&display=swap&subset=cyrillic');

$color-primary: #FFB300;
$color-border-color-primary: #262654;

$color-text-primary: #F5F5FF;
$color-text-secondary: #A8A8D4;

$color-bg-primary: #191934;
$color-bg-secondary: #191934;
$gutter: 30px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
html, body {
  height: 100%;
}
body {
  background: $color-bg-primary;
  color: $color-text-primary;
  font-family: 'Roboto', sans-serif;  
}
#root {
  height: 100%;
}
.container {
  width: 100%;
  height: 100%;
}
.wrap-content {
  width: 1290px;
}

@import '../header/header';
@import '../about/about';
@import '../service/service';
@import '../footer/footer';