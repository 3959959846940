.service {
  display: flex;
  justify-content: center;
  padding-bottom: 150px;
  .service-title {
    display: flex;
    flex-flow: column nowrap;
    align-items: center; 
    margin-bottom: 100px; 
    span {
      font-size: 1.25rem;
      font-weight: 300;
      color: $color-text-secondary;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 2.5rem;
      font-weight: 500;
      color: $color-text-primary;
    }
  }
  .services-list {
    display: flex;
    flex-flow: row wrap;
    .service-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 3px solid $color-border-color-primary;
      border-radius: 10px;
      padding: 60px;
      line-height: 30px;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
      ul {
        font-weight: 300;
        list-style: none;
        li {
          padding-left: 15px;      
          &::before {
            content: '';
            width: 3px;
            height: 10px;
            position: absolute;
            top: 10px;            
            left: 0; 
            background: $color-primary;           
          }
        }       
      }
    }
    .item-title {
      font-size: 1.875rem;
      font-weight: 500;
      margin-bottom: 15px;
      line-height: 2rem;
    }
    .item-description {
      font-weight: 300;
      color: $color-text-secondary;
    }
    .col-2 {
      width: calc(50% - 30px);
    }
  }
}