.about {
  display: flex;
  justify-content: center;
  padding: 150px 0;
  .about__title {
    display: flex;
    flex-flow: column nowrap;
    align-items: center; 
    margin-bottom: 100px; 
    span {
      font-size: 1.25rem;
      font-weight: 300;
      color: $color-text-secondary;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 2.5rem;
      font-weight: 500;
      color: $color-text-primary;
    }
  }
  .about__text {
    display: flex;
    justify-content: space-between;
    div {
      width: calc(50% - 30px);
      p {
        font-size: 1.125rem;
        font-weight: 300;
        color: $color-text-secondary;
        margin-bottom: 50px;
        line-height: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }    
  }
}