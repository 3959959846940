.footer {
  height: 400px;
  background: #16162B;
  display: flex;
  justify-content: center;
  font-weight: 300;

  .footer-wrap {
    width: 1290px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  i {
    font-style: normal;
    color: $color-text-secondary;
  }

  .phone {
    margin-bottom: 20px;

    .phoneLink {
      font-size: 1.625rem;
      text-decoration: none;
      color: $color-text-primary;

      &::after {
        content: '';
        width: 0;
        height: 2px;
        background: $color-primary;
        position: absolute;
        left: 0;
        bottom: -5px;
        transition: 0.4s;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  .email {
    .emailLink {
      text-decoration: none;
      color: $color-text-primary;
      &::after {
        content: '';
        width: 0;
        height: 2px;
        background: $color-primary;
        position: absolute;
        left: 0;
        bottom: -5px;
        transition: 0.4s;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  .col-2 {
    width: 50%;
    display: flex;

    &.inner {
      flex-flow: column nowrap;
      justify-content: space-between;
    }
  }
}