.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-bg-secondary;
  padding: 30px 0;
  .header-top-panel {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 30px;
    left: 0;
  }
  .top {
    width: 1290px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
  }
  .nav{
    ul {
      list-style: none;
      display: flex;
      li {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
      a {
        font-weight: 300;
        color: $color-text-primary;
        text-decoration: none; 
      }      
    }
  }
  .header-wrap {
    width: 1290px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-title {
    width: calc(50% - 30px);
    h1 {
      font-weight: 500;
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
    span {
      font-weight: 300;
      font-size: 1.5625rem;
    }
  }
  .iconMore {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
  }
  .slide {
     width: calc(50% - 30px);
  }
}